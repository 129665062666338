<template>
  <v-card :loading="ui.loading" :disabled="ui.disabled" class="rounded-lg overflow-hidden">
    <v-card-text class="px-0 pb-0">
      <v-row class="d-flex"
        style="border-radius: 0">
        <v-col cols="5" class="d-flex">
          <v-row>
            <v-col cols="12" class="blue py-6 px-12">
              <v-toolbar-title class="d-flex flex-column text-center title white--text pl-0 pb-10">
                <v-icon class="my-10" size="125">mdi-currency-eur</v-icon>
              </v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="7" class="grey lighten-3 elevation-4 py-8 px-8">
          <v-row>
            <v-col class="d-flex justify-end">
              <v-icon @click="close">mdi-close </v-icon>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-column">
            <v-col>
              <div class="button text-uppercase pb-4">
                {{ $vuetify.lang.t('$vuetify.administration.vat.add.name')  }}
              </div>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-form
            ref="form"
            v-model="form.valid"
            lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-alert
                v-model="ui.alert.enable"
                :dismissible="ui.alert.dismissible"
                :icon="ui.alert.icon"
                :type="ui.alert.type"
                class="mb-0 mt-3 mb-3"
              >
                {{ ui.alert.message }}
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.data.code"
                :counter="20"
                :rules="form.rules.vat_code"
                :label="$vuetify.lang.t('$vuetify.administration.vat.add.ui.form.fields.code.label')+'*'"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.data.value"
                :counter="20"
                suffix="%"
                :rules="form.rules.vat_value"
                :label="$vuetify.lang.t('$vuetify.administration.vat.add.ui.form.fields.value.label')+'*'"
                required
              ></v-text-field>
            </v-col>
            <v-card-actions class="d-xs-block px-3 px-sm-7">
              <v-btn
                text
                v-on:click="reset()">
                {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
              </v-btn>
              <v-btn
                color="primary"
                text
                v-on:click="submit()">
                {{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}
              </v-btn>
            </v-card-actions>
          </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  props: {
    onCancel: {
      type: Function,
      required: true
    },
    onConfirm: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    ui: {
      loading: false,
      disabled: false,
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    form: {
      valid: false,
      data: {
        code: '',
        value: ''
      },
      rules: {}
    }
  }),
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    async submit () {
      if (!this.validate()) {
        return false
      }
      try {
        this.ui.loading = true
        this.ui.disabled = true

        await this.axios.post('/administration/vat/add/', {
          code: this.form.data.code,
          value: this.form.data.value
        }, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })
        this.reset()
        this.onConfirm()
        this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.administration.vat.add.notification.added'))
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 400:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.administration.vat.add.notification.fail'))
              break
            case 406:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.administration.vat.edit.notification.vat_exist'))
              break

            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.administration.vat.edit.notification.unknown_save'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    },
    validate () {
      return this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.ui.alert.enable = false
    },
    close () {
      this.reset()
      this.onCancel()
    }
  },
  created () {
    this.form.rules = {
      vat_code: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => (value && value.length <= 20) || this.$vuetify.lang.t('$vuetify.global.max_char')
      ],
      vat_value: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => !isNaN(value) || this.$vuetify.lang.t('$vuetify.administration.vat.edit.ui.form.fields.value.rules.value_number')
      ]
    }
  }
}
</script>
